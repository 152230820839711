import React from "react";
import "./style.css";
import shield from "../../assets/ULTRA_shield_transparent.png";
import TextEffect from "../TextEffect";

export default function FlyRocket() {
  return (
    <div className="FlyRocket mountAnimBelow" id="Features">
      <div className="firstLine">
        ULTRA{" "}
        <span>
          <TextEffect>Shield</TextEffect>
        </span>
      </div>
      <div className="secondLine">
        Features
        <span>
          <img src={shield} alt="shield" />
        </span>
      </div>
    </div>
  );
}
