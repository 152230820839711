const sen1 = "Framework.";
const sen2 = "Anti Executor.";
const sen3 = "Trigger Protection.";
const sen4 = "Cloud Panel.";

const det1 = "ESX, QBCORE and VRP";
const det2 = "Eulen and RedEngine";
const det3 = "Server and Client Events";
const det4 = "User-friendly interface for convenience.";

const ArrayData = [
  { title: sen1, detail: det1 },
  { title: sen2, detail: det2 },
  { title: sen3, detail: det3 },
  { title: sen4, detail: det4 },
  { title: sen1, detail: det1 },
  { title: sen2, detail: det2 },
  { title: sen3, detail: det3 },
  { title: sen4, detail: det4 },
];

export default ArrayData;
